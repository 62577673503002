
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import dynamic from 'next/dynamic';
import { GetServerSidePropsContext, NextPage, PreviewData } from 'next/types';
import { ParsedUrlQuery } from 'node:querystring';
import { ContextData } from '@common/defaults';
import { ItemsPerPage } from '@common/types';
import { fetchHomepageNews } from '@web/handlers/fetchHomepageNews';
import { getNextWebServerSidePropsWrapper, GetProps, GetPropsReturn, Route } from '@web/routing';
import type { Props } from '@web/templates/ErrorPage';
import { getErrorTranslations } from '@web/templates/ErrorPage/utils';
const PageNotFoundView = dynamic(() => import('@web/templates/ErrorPage/PageNotFoundView').then((module) => module.PageNotFoundView));
const DefaultErrorView = dynamic(() => import('@web/templates/ErrorPage/DefaultErrorView').then((module) => module.DefaultErrorView));
interface ErrorProps {
    errorCode?: number;
    contextData?: ContextData;
}
const prepareProps: GetProps<ErrorProps> = async (contextData: ContextData, serverContext: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>): GetPropsReturn<Props | Props<404>> => {
    const statusCode = serverContext.res.statusCode || 500;
    const props = {
        contextData,
        errorCode: statusCode
    };
    if (statusCode !== 404) {
        return props;
    }
    const [news, translations] = await Promise.all([
        fetchHomepageNews(contextData, 1, false, ItemsPerPage.SMALL),
        getErrorTranslations(contextData.context.locale, contextData.platform),
    ]);
    return {
        ...props,
        state: {
            news: news?.data ?? [],
            translations
        }
    };
};
const CustomError: NextPage<Props<void | 404>> = (props) => {
    // ? : Translations only work when:
    //   -  useTranslation hook is called within / pages
    //   - getT is used in getServerSideProps
    if (props?.errorCode === 404 && 'state' in props) {
        return <PageNotFoundView {...props}/>;
    }
    return <DefaultErrorView {...props}/>;
};
const getServerSideProps = getNextWebServerSidePropsWrapper(prepareProps, Route.Error);
export default CustomError;

    async function __Next_Translate__getServerSideProps__193c0c3ea6c__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/_error',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__193c0c3ea6c__ as getServerSideProps }
  